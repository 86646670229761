import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import { createColumnHelper } from "@tanstack/react-table";
import "./ridelist.css";
import Navbar from "../../../components/navbar";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
import { deleteRideApi, getRidesList } from "services/customAPI";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";
import deleteIcon from "../../../assets/svg/deleteIcon.svg";
import { useDisclosure } from "@chakra-ui/hooks";
import CustomModal from "./modal";
import { CSVLink } from "react-csv";
import { BsFlagFill } from "react-icons/bs";

type RowObj = {
  source: string;
  destination: string;
  time: string;
  date: string;
  status: string;
  rider_count: string;
  type: string;
  driver_name: string;
  action: string;
};


const columnHelper = createColumnHelper<RowObj>();

const Ride = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rideCsvData, setRideCsvData] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    limit: 10,
    noData: false,
  });

  const headers = [
    { label: "mongoId", key: "_id" },
    { label: "Source", key: "source" },
    { label: "Destination", key: "destination" },
    { label: "Time", key: "time" },
    { label: "Date", key: "date" },
    { label: "Status", key: "status" },
    { label: "Completly Booked", key: "completly_booked" },
    { label: "Rider Count", key: "rider_count" },
    { label: "Ride Type", key: "type" },
    { label: "Driver First Name", key: "driver_fName" },
    { label: "Driver Last Name", key: "driver_lName" },
    { label: "Riders", key: "riders" }
  ];

  const columns = [
    columnHelper.accessor("source", {
      id: "source",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Source
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("destination", {
      id: "destination",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Destination
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("time", {
      id: "time",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Time
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date (yy-mm-dd)
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Status
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("rider_count", {
      id: "rider_count",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Rider count
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),

     columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Ride Type
        </p>
      ),
      cell: (info) => (
        <p
          className={`text-lg font-bold ${
            info.getValue() === "POOL_RIDE" ? "text-navy-500" : "text-yellow-500"
          } dark:text-white`}
        >
          <BsFlagFill />
        </p>
      ),
    }),

    columnHelper.accessor("driver_name", {
      id: "driver_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Driver
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <div className="flex">
            <div>
              <img
                src={ButtonEdit}
                className="me-2 cursor-pointer"
                onClick={() =>
                  navigate(`/admin/ride/rideForm/${info.getValue()}`)
                }
              />
            </div>
            <div>
              <img
                src={deleteIcon}
                className="me-2 cursor-pointer"
                onClick={() => handleClickForModal(info.row.original)}
              />
            </div>
          </div>
        </div>
      ),
    }),
  ];

  const handleClickForModal = (data: any) => {
    setIsLoading(true);
    setSelectedItem(data);
    onOpen();
    setIsLoading(false);
  }

  async function convertToUsableArray(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (ride) => {
        return {
          source: ride?.source,
          destination: ride?.destination,
          time: ride?.time,
          status: ride?.status,
          rider_count: ride?.rider_count ? String(ride?.rider_count) : "0",
          type: ride?.type,
          date: ride?.date ? ride?.date.slice(0, 10) : "",
          driver_name: ride?.driver.length > 0 ? ride?.driver[0].first_name.concat(" ", ride.driver[0].last_name) : "N/A",
          action: ride?._id
        };
      })
    );
  }

  const getRideListing = async () => {
    setIsLoading(true);
    try {
      const response: any = await getRidesList({
        page: currentPage.current,
        limit: state.limit,
        text: state.searchText.trim(),
      });
      console.log("response", response)
      if (response?.data?.code === 200) {
        if (response?.data?.data?.[0].rides?.length === 0) {
          setState((prev: any) => ({
            ...prev,
            noData: true,
            pageCount: 0,
          }));
          setRideCsvData([])
        } else {
          setState((prev: any) => ({
            ...prev,
            pageCount: Math.ceil(response?.data?.data?.[0].totalRides?.[0]?.totalcount / state.limit),
            searchText: state.searchText,
            noData: false,
          }));

          setRideCsvData(
            response?.data?.data?.[0]?.rides.map((ride: any) => {
              let ridersDetails = "";
              if (ride?.riders?.length > 0) {
                for (let i = 0; i < ride.riders.length; i++) {
                  // data = {...data , [driver.documents[i].document_name] : driver.documents[i].document_key}
                  ridersDetails = ridersDetails + `[${ride?.riders?.[i]?.name},${ride?.riders?.[i]?.phone_number}],`
                }
              }
              return {
                _id: ride?._id,
                source: ride?.source,
                destination: ride?.destination,
                time: ride?.time,
                date: ride?.date?.slice(0, 10),
                status: ride?.status,
                completly_booked: ride?.completly_booked,
                rider_count: ride?.rider_count,
                type: ride?.type,
                driver_fName: ride?.driver?.[0]?.first_name,
                driver_lName: ride?.driver?.[0]?.last_name,
                riders: ridersDetails
              }
            })
          )
          setTableData(await convertToUsableArray(response?.data?.data?.[0]?.rides));
        }
      } else {
        throw Error(response.data?.message || "Something went wrong")
      }
    } catch (error: any) {
      toaster.errorToast(error?.response?.data?.data?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteHandle = async (id: any) => {
    setIsLoading(true);
    onClose();
    try {
      const response: any = await deleteRideApi(id);
      if (response?.status === 200) {
        toaster.successToast("Ride deleted successfully");
        currentPage.current = 1;
        getRideListing()
      } else {
        toaster.errorToast(response?.message || "Error occured while deleting ride");
      }
    } catch (error: any) {
      toaster.errorToast(error?.response?.data?.message || error?.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  }

  function handlePageClick(e: any) {
    currentPage.current = e.selected + 1;
    getRideListing()
  }

  const handleSearchSubmit = async (e: any) => {
    if (state.searchText.trim() == "") {
      return;
    }
    currentPage.current = 1;
    getRideListing()
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getRideListing()
    } else {
      if (state.searchText == "") {
        currentPage.current = 1;
        getRideListing()
      }
    }
  }, [state.searchText]);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Rides"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Rides"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                buttonName="Add Ride"
                showExtraButton={true}
                extraButtonName="Add Bulk Rides"
                extraButtonNavigation="/admin/ride/bulkRideForm"
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                showSelect={false}
                navigation="/admin/ride/rideForm"
              />
              <Table
                tableData={tableData}
                columns={columns}
                noData={state.noData}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: "#5E6278",
                  marginTop: "10px",
                  alignItems: "center"
                }}
              >
                <div>
                  {rideCsvData.length > 0 &&
                    <CSVLink data={rideCsvData} filename={"rideRecord.csv"}
                      className="px-2 py-1 bg-[#ff9966] text-white text-[14px] rounded-sm"
                      target="_blank" headers={headers}>
                      Download CSV
                    </CSVLink>}
                </div>
                <div>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
              <div>
                {isOpen && (
                  <CustomModal onClose={onClose} isOpen={isOpen} source={selectedItem.source} destination={selectedItem.destination} id={selectedItem.action} deleteHandle={deleteHandle} />
                )}
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default Ride;
