import Driver from "./views/admin/driver";
import Ride from "./views/admin/ride";
import DriverForm from "./views/admin/driver/driverDetails";
import RideForm from "./views/admin/ride/rideForm";
import BulkRideForm from "./views/admin/ride/bulkRideForm";
import Users from "./views/admin/rider";
import { BiSolidDashboard } from "react-icons/bi";
import Locations from "views/admin/loction";
import LocationForm from "views/admin/loction/locationForm";
import BulkLocationForm from "views/admin/loction/bulkLocationForm";

const routes = [
  {
    name: "Drivers",
    layout: "/admin",
    path: "default",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <Driver />,
  },
  {
    name: "DriverForm",
    layout: "/admin",
    path: "default/driverForm/:id",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <DriverForm />,
    secondary: true,
  },
  // {
  //   name: "Schedules",
  //   layout: "/admin",
  //   path: "schedule",
  //   icon: <BiSolidDashboard className="h-6 w-6" />,
  //   component: <Schedule />,
  // },
  // {
  //   name: "ScheduleForm",
  //   layout: "/admin",
  //   path: "schedule/scheduleForm",
  //   icon: <BiSolidDashboard className="h-6 w-6" />,
  //   component: <ScheduleForm />,
  //   secondary: true,
  // },
  // {
  //   name: "ScheduleForm",
  //   layout: "/admin",
  //   path: "schedule/scheduleForm/:id",
  //   icon: <BiSolidDashboard className="h-6 w-6" />,
  //   component: <ScheduleForm />,
  //   secondary: true,
  // },
  {
    name: "Rides",
    layout: "/admin",
    path: "ride",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <Ride />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <Users />,
  },
  {
    name: "RideForm",
    layout: "/admin",
    path: "ride/rideForm",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <RideForm />,
    secondary: true,
  },
  {
    name: "RideForm",
    layout: "/admin",
    path: "ride/rideForm/:id",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <RideForm />,
    secondary: true,
  },
  {
    name: "BulkRideForm",
    layout: "/admin",
    path: "ride/bulkRideForm",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <BulkRideForm />,
    secondary: true,
  },
  {
    name: "Locations",
    layout: "/admin",
    path: "location",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <Locations />,
  },
  {
    name: "LocationForm",
    layout: "/admin",
    path: "location/locationForm",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <LocationForm />,
    secondary: true,
  },
  {
    name: "RideForm",
    layout: "/admin",
    path: "location/locationForm/:id",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <LocationForm />,
    secondary: true,
  },
  {
    name: "BulkRideForm",
    layout: "/admin",
    path: "location/bulkLocationForm",
    icon: <BiSolidDashboard className="h-6 w-6" />,
    component: <BulkLocationForm />,
    secondary: true,
  },
];
export default routes;
