import { Button, ChakraProvider, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { TiTick } from "react-icons/ti";
import "../driverslist.css";

const CustomModal = (props: any) => {
    const { isOpen, onClose, name, id, approveHandle } = props
    return (
        <ChakraProvider>
            <Modal
                isCentered={true}
                isOpen={isOpen}
                onClose={onClose}
                size="xs"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <div className="mb-2 flex justify-center">
                        <TiTick size={24} color='green' />
                    </div>
                    <ModalBody className="text-center">
                        Are you sure you want to Approve verification of<br />
                        {'"' + name + '"'}
                    </ModalBody>

                    <div className="mt-3 flex justify-center">
                        <Button
                            className="cancel-delete-modal-button mx-2"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="delete-modal-button-d mx-2"
                            onClick={() => approveHandle(id)}
                        >
                            Approve
                        </Button>
                    </div>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    )
}

export default CustomModal