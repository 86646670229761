import Loader from "components/loader/loader";
import Navbar from "components/navbar";
import { useRef, useState } from "react";
import Card from "../../../../components/card";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@chakra-ui/react";
import "./bulkLocationForm.css";
import { createBulkLocation } from "services/customAPI";
import { toaster } from "components/common/toaster";

const BulkRideForm = (props: any) => {
    const { sideBarUseState, setSideBarUseState } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [csvArray, setCsvArray] = useState([]);
    const [csvFileName, setCsvFileName] = useState("");
    const csvRef = useRef(null);
    const [csvFormValues, setCsvFormValues] = useState({
        csvFile: null,
    });
    const [errorRow, setErrorRow] = useState(null);
    const [errorArr, setErrorArr] = useState([]);
    const [failedLocations, setFailedLocations] = useState([])

    const SUPPORTED_FORMATS = ["text/csv"];

    const csvSchema = Yup.object().shape({
        csvFile: Yup.mixed()
            .required("csv file is required")
            .test(
                "fileFormat",
                "Unsupported Format",
                (value: any) => value && SUPPORTED_FORMATS.includes(value.type)
            ),
    });

    const processCSV = (str: any, delim = ",") => {
        str = str.toString().replaceAll("\r", "");
        const headers = str.slice(0, str.indexOf("\n")).split(delim);
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");

        const newArray = rows.map((row: any) => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj: any, header: any, i: any) => {
                obj[header] = values[i];
                return obj;
            }, {});
            return eachObject;
        });

        newArray.pop();
        const modified = newArray.map((row: any) => {
            return {
                name: row.name.replace(/\*/g, ","),
                location_link: row.location_link.replace(/\*/g, ","),
                lat: parseFloat(row.lat.replace(/\*/g, ",")),
                long: parseFloat(row.long.replace(/\*/g, ",")),
            };
        });
        setCsvArray(modified);
    };

    const downloadRefCsv = () => {
        // Create a link element
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_FRONTEND_URL}/locationRef.csv`;
        // Set the download attribute and filename
        link.download = "locationRef.csv";
        // link.target = "_blank"
        // Trigger the download
        link.click();
    };

    const csvOnChange = (file: any) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            const text = e.target.result;
            processCSV(text);
        };
        reader.readAsText(file);
    };

    const coordinateValidation = (value: any) => {
        // Check if value has a dot after the second digit
        return /^\d{2}\.[0-9]+$/.test(value);
    };

    const LocationSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Location must be atleast two characters.")
            .required("Location is required")
            .test(
                "no-empty-spaces",
                "Location cannot be empty spaces",
                (value) => value.trim() !== ""
            ),
        location_link: Yup.string()
            .min(2, "Link must be atleast two characters.")
            .required("Link is required")
            .test(
                "no-empty-spaces",
                "Link cannot be empty spaces",
                (value) => value.trim() !== ""
            ),
        lat: Yup.string()
            .required("Location latitude is required")
            .test(
                "no-empty-spaces",
                "Location latitude cannot be empty spaces",
                (value) => value.trim() !== ""
            )
            .test(
                "coordinate-format",
                "Latitude should have decimal point after two digits",
                coordinateValidation
            ),
        long: Yup.string()
            .required("Location latitude is required")
            .test(
                "no-empty-spaces",
                "Source latitude cannot be empty spaces",
                (value) => value.trim() !== ""
            )
            .test(
                "coordinate-format",
                "Latitude should have decimal point after two digits",
                coordinateValidation
            ),
    });

    const validateArray = async (dataArray: any) => {
        const validationPromises = dataArray.map((data: any) =>
            LocationSchema.validate(data, { abortEarly: false })
        );

        try {
            await Promise.all(validationPromises);
            // All objects in the array are valid
            return [];
        } catch (errors: any) {
            // Some objects are invalid
            const validationErrors: any = [];
            setErrorRow(JSON.stringify(errors.value));
            errors.inner.forEach((err: any) => {
                validationErrors.push({
                    path: err.path,
                    message: err.message,
                });
            });
            return validationErrors;
        }
    };

    const submitBulkRides = async (values: any) => {
        try {
            setIsLoading(true);
            const resp = await validateArray(csvArray);
            if (resp.length !== 0) {
                setErrorArr(resp);
                throw new Error("Validation Error");
            }
            const response = await createBulkLocation(csvArray);
            if (response.status === 200 || response.status === 201) {
                if (response.data.failure.length > 0) {
                    setFailedLocations(response.data.failure);
                    toaster.errorToast("All Locations not created successfully");
                } else {
                    toaster.successToast("All Locations created successfully");
                    window.history.back();
                }
            } else {
                throw new Error("Something went wrong");
            }
        } catch (error: any) {
            toaster.errorToast(
                error?.response?.data?.message ||
                error?.message ||
                "Something went wrong"
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Navbar
                flag={false}
                brandText="BulkRideForm"
                sideBarUseState={sideBarUseState}
                setSideBarUseState={setSideBarUseState}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10 mb-5"}>
                        <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
                            <div className="text-xl font-bold text-brand-500 dark:text-white">
                                Add Bulk Locations
                            </div>
                        </header>
                        <div className="ps-[10%] pt-6 sm:ps-20">
                            Download reference CSV
                            <span
                                onClick={downloadRefCsv}
                                className="cursor-pointer text-[18px] font-medium text-[#00cc66]"
                            >
                                {" "}
                                Here
                            </span>
                        </div>
                        <div className="pb-6 pt-2 sm:pb-5 sm:pe-20 sm:ps-20">
                            <Formik
                                enableReinitialize={true}
                                initialValues={csvFormValues}
                                onSubmit={(values: any) => {
                                    submitBulkRides(values);
                                }}
                                validationSchema={csvSchema}
                            >
                                {({ values, handleBlur, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="button-save-cancel mt-3 flex items-center justify-between">
                                            <div className="w-[40%]">
                                                <div
                                                    onClick={() => {
                                                        csvRef.current.click();
                                                    }}
                                                    className="relative mr-4 w-full cursor-pointer rounded-[8px] border border-[#9CA3AF] sm:h-[60px]"
                                                >
                                                    <div className="flex h-full items-center justify-center">
                                                        {values.csvFile && csvFileName
                                                            ? csvFileName
                                                            : "Upload Csv File Here"}
                                                    </div>
                                                </div>
                                                {values.csvFile && csvFileName && (
                                                    <div className="flex justify-start">
                                                        <span
                                                            onClick={() => {
                                                                values.csvFile = null;
                                                                setCsvFileName("");
                                                                csvRef.current.value = null;
                                                            }}
                                                            className="cursor-pointer text-[18px] font-medium text-[#a6a6a6]"
                                                        >
                                                            Reset
                                                        </span>
                                                    </div>
                                                )}
                                                <input
                                                    onBlur={handleBlur}
                                                    className="hidden"
                                                    name="csvFile"
                                                    id="csvFile"
                                                    ref={csvRef}
                                                    type="file"
                                                    accept=".csv"
                                                    onChange={(e) => {
                                                        if (e.target.files[0]) {
                                                            setFieldValue("csvFile", e.target.files[0]);
                                                            csvOnChange(e.target.files[0]);
                                                            setCsvFileName(e.target.files[0].name);
                                                        }
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="csvFile"
                                                    component="div"
                                                    className="error-input"
                                                />
                                            </div>

                                            <div>
                                                <Button
                                                    className=" cancel-button my-2 ms-1 sm:my-0"
                                                    onClick={() => {
                                                        window.history.back();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="save-button  my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                                                    style={{ width: '115px' }}
                                                >
                                                    Add Locations
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </Card>
                    {errorRow && (
                        <Card
                            extra={"w-full pb-6 p-4 h-full pt-6 mb-10"}
                            background="#ffe6e6"
                        >
                            <button
                                onClick={() => {
                                    setErrorArr([]);
                                    setErrorRow(null);
                                }}
                                type="button"
                                className="absolute right-2 top-2 rounded-full bg-[#ff4d4d] px-[7px] text-white"
                            >
                                X
                            </button>
                            <div className="px-2">
                                <div className="pb-2 text-[18px] font-medium text-[#b30000]">
                                    Location which having validation error :{" "}
                                </div>
                                <div className="w-full break-words">{errorRow}</div>
                            </div>
                            <div className="px-2">
                                <div className="py-2 text-[18px] font-medium text-[#b30000]">
                                    Errors which may be causing validation error
                                </div>
                                {errorArr.length > 0 &&
                                    errorArr.map((err, index) => {
                                        return (
                                            <div key={index}>
                                                <span className="font-medium">Column:</span>{" "}
                                                <span className="text-[#ff0000]">{err.path}</span>
                                                <span className="font-medium">, ErrorMesage:</span>{" "}
                                                <span className="text-[#ff0000]">{err.message}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Card>
                    )}
                    {failedLocations.length > 0 && (
                        <Card
                            extra={"w-full pb-6 p-4 h-full pt-6 mb-10"}
                            background="#ffe6e6"
                        >
                            <button
                                onClick={() => {
                                    setFailedLocations([]);
                                }}
                                type="button"
                                className="absolute right-2 top-2 rounded-full bg-[#ff4d4d] px-[7px] text-white"
                            >
                                X
                            </button>
                            <div className="px-2">
                                <div className="py-2 text-[18px] font-medium text-[#b30000]">
                                    Locations which are not created successfully :
                                </div>
                                {failedLocations.length > 0 &&
                                    failedLocations.map((location, index) => {
                                        return (
                                            <div className="mb-2 w-full break-words" key={index}>
                                                <span className="font-medium">Location {index + 1}:</span>{" "}
                                                <span className="">{JSON.stringify(location)}</span>
                                                <br />
                                                <span className="font-medium">Reason :</span>{" "}
                                                <span className="text-[#ff0000]">{location?.reason}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default BulkRideForm;
