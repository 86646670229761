import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import { createColumnHelper } from "@tanstack/react-table";
import "./locationlist.css";
import Navbar from "../../../components/navbar";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
import { getLocationListWithPaginationApi } from "services/customAPI";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";

type RowObj = {
  name: string;
  location_link: string;
  createdAt: string;
  action: string;
};

const columnHelper = createColumnHelper<RowObj>();

const Locations = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [driverCsvData, setDriverCsvData] = useState([]);
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    limit: 10,
    noData: false,
  });

  const headers = [
    { label: "Name", key: "name" },
    { label: "Link", key: "location_link" },
    { label: "Created At", key: "createdAt" },
  ];

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Name</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("location_link", {
      id: "location_link",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Link</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          <a href={info.getValue()}>{info.getValue()}</a>
        </p>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date Added
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().split("T")[0]}
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <div className="flex">
            <div>
              <img
                alt="edit"
                src={ButtonEdit}
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/admin/location/locationForm/${info.getValue()}`)
                }
              />
            </div>
          </div>
        </div>
      ),
    }),
  ];

  async function convertToUsableArray(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (location) => {
        return {
          name: location?.name,
          location_link: location?.location_link,
          createdAt: location?.createdAt,
          action: location?._id,
        };
      })
    );
  }

  const getLocationListing = async () => {
    setIsLoading(true);
    try {
      const response: any = await getLocationListWithPaginationApi({
        page: currentPage.current,
        limit: state.limit,
        text: state.searchText.trim(),
      });
      if (response?.data?.code === 200) {
        if (response?.data?.data?.[0].location?.length === 0) {
          setState((prev: any) => ({
            ...prev,
            noData: true,
            pageCount: 0,
          }));
          setDriverCsvData([]);
        } else {
          setState((prev: any) => ({
            ...prev,
            pageCount: Math.ceil(
              response?.data?.data?.[0].totalLocations?.[0]?.totalcount /
              state.limit
            ),
            searchText: state.searchText,
            noData: false,
          }));
          setDriverCsvData(
            response?.data?.data?.[0]?.location.map((location: any) => {
              let data = {
                name: location?.name,
                location_link: location?.location_link,
                createdAt: location?.createdAt,
              };

              if (location?.documents?.length > 0) {
                for (let i = 0; i < location?.documents?.length; i++) {
                  data = {
                    ...data,
                    [location?.documents?.[i]?.document_name]:
                      location?.documents?.[i]?.document_key,
                  };
                }
              }
              return data;
            })
          );

          setTableData(
            await convertToUsableArray(response?.data?.data?.[0]?.location)
          );
        }
      } else {
        throw Error(response.data?.message);
      }
    } catch (error: any) {
      toaster.errorToast(error?.response?.data?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  function handlePageClick(e: any) {
    currentPage.current = e.selected + 1;
    getLocationListing();
  }

  const handleSearchSubmit = async (e: any) => {
    if (state.searchText.trim() === "") {
      return;
    }
    currentPage.current = 1;
    getLocationListing();
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getLocationListing();
    } else {
      if (state.searchText === "") {
        currentPage.current = 1;
        getLocationListing();
      }
    }
  }, [state.searchText]);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Locations"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Locations"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                showSelect={false}
                showExtraButton={true}
                buttonName="Add Location"
                navigation="/admin/location/locationForm"
                extraButtonName="Add Bulk Location"
                extraButtonNavigation="/admin/location/bulkLocationForm"
              />
              <Table
                tableData={tableData}
                columns={columns}
                noData={state.noData}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: "#5E6278",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  {driverCsvData.length > 0 && (
                    <CSVLink
                      data={driverCsvData}
                      filename={"locations.csv"}
                      className="rounded-sm bg-[#ff9966] px-2 py-1 text-[14px] text-white"
                      target="_blank"
                      headers={headers}
                    >
                      Download CSV
                    </CSVLink>
                  )}
                </div>
                <div>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default Locations;
