import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "layouts/admin";

const ProtectedRoute = React.lazy(
  () => import("./views/routes/protectedRoutes")
);
const Login = React.lazy(() => import("./views/login/login"));

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to="/admin" replace />} />
      <Route element={<ProtectedRoute />}>
        <Route path="admin/*" element={<AdminLayout />} />
      </Route>
    </Routes>
  );
};

export default CustomRoutes;
