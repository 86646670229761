import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import { createColumnHelper } from "@tanstack/react-table";
import "./riderlist.css";
import Navbar from "../../../components/navbar";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
import { getRidersList, getRidesList } from "services/customAPI";
import { CSVLink } from "react-csv";

type RowObj = {
  full_name: string;
  phone_number: string;
  email: string;
  city_residence: string;
  from: string;
  to: string;
  createdAt: string;
};

const columnHelper = createColumnHelper<RowObj>();

const Rider = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [riderCsvData, setRiderCsvData] = useState([]);
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    limit: 10,
    noData: false,
  });

  const headers = [
    { label: "Full Name", key: "full_name" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Email", key: "email" },
    { label: "From", key: "from" },
    { label: "To", key: "to" },
    { label: "Residence", key: "city_residence" },
    { label: "Frequency", key: "compute_frequency" },
    { label: "Date Added", key: "createdAt" },
  ];

  const columns = [
    columnHelper.accessor("full_name", {
      id: "full_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Full Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("phone_number", {
      id: "phone_number",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Phone No.
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("from", {
      id: "from",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">From</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("to", {
      id: "to",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">To</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("city_residence", {
      id: "city_residence",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          City Residence
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date Added
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().split("T")[0]}
        </p>
      ),
    }),
  ];

  async function convertToUsableArray(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (ride) => {
        return {
          full_name: ride?.full_name || "N/A",
          phone_number: ride?.phone_number,
          email: ride?.email || "N/A",
          from: ride?.from || "N/A",
          to: ride?.to || "N/A",
          city_residence: ride?.city_residence || "N/A",
          createdAt: ride?.createdAt || "N/A",
        };
      })
    );
  }

  const getRiderListing = async () => {
    setIsLoading(true);
    try {
      const response: any = await getRidersList({
        page: currentPage.current,
        limit: state.limit,
        text: state.searchText.trim(),
      });
      if (response?.data?.code === 200) {
        if (response?.data?.data?.[0].riders?.length === 0) {
          setState((prev: any) => ({
            ...prev,
            noData: true,
            pageCount: 0,
          }));
          setRiderCsvData([]);
        } else {
          setState((prev: any) => ({
            ...prev,
            pageCount: Math.ceil(
              response?.data?.data?.[0].totalRiders?.[0]?.totalcount /
                state.limit
            ),
            searchText: state.searchText,
            noData: false,
          }));
          setRiderCsvData(
            response?.data?.data?.[0]?.riders.map((rider: any) => {
              return {
                full_name: rider?.full_name,
                phone_number: rider?.phone_number,
                email: rider?.email,
                from: rider?.from,
                to: rider?.to,
                city_residence: rider?.city_residence,
                compute_frequency: rider?.compute_frequency,
              };
            })
          );
          setTableData(
            await convertToUsableArray(response?.data?.data?.[0]?.riders)
          );
        }
      } else {
        throw Error(response.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toaster.errorToast(
        error?.response?.data?.data?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  function handlePageClick(e: any) {
    currentPage.current = e.selected + 1;
    getRiderListing();
  }

  const handleSearchSubmit = async (e: any) => {
    if (state.searchText.trim() == "") {
      return;
    }
    currentPage.current = 1;
    getRiderListing();
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getRiderListing();
    } else {
      if (state.searchText === "") {
        currentPage.current = 1;
        getRiderListing();
      }
    }
  }, [state.searchText]);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Rides"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Users"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                showSelect={false}
                showAddCategoryButton={false}
              />
              <Table
                tableData={tableData}
                columns={columns}
                noData={state.noData}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: "#5E6278",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  {riderCsvData?.length > 0 && (
                    <CSVLink
                      data={riderCsvData}
                      filename={"riderRecord.csv"}
                      className="rounded-sm bg-[#ff9966] px-2 py-1 text-[14px] text-white"
                      target="_blank"
                      headers={headers}
                    >
                      Download CSV
                    </CSVLink>
                  )}
                </div>
                <div>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default Rider;
