import Loader from "components/loader/loader";
import Navbar from "components/navbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../../../components/card";
import crossIcon from "../../../../assets/svg/whiteBorderCrossBtn.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Button } from "@chakra-ui/react";
import "./rideForm.css";
import {
  createRide,
  getDriverApi,
  getDriversDetails,
  getLocationListApi,
  getRide,
  qrcode,
  removeRiderApi,
  updateRide,
} from "services/customAPI";
import { toaster } from "components/common/toaster";
import { DatePicker, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import deleteIcon from "../../../../assets/svg/deleteIcon.svg";

type formvalues = {
  source: string;
  destination: string;
  time: string;
  date: string;
  driver_uid: string;
};

const RideForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [driverOption, setDriverOption] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [riderArr, setRiderArr] = useState([]);
  const [rideType, setRideType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationList, setLocationList] = useState(null);
  const [deleteRidersArr, setDeleteRidersArr] = useState([]);
  const [driverDetail, setDriverDetail] = useState<any>([]);
  const [driverPaymentStatus, setDriverPaymentStatus] = useState("UNPAID");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [rideStatus, setRideStatus] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [locationOptions, setLocationOptions] = useState([
    {
      value: "Loading",
      label: "Loading",
    },
  ]);
  const params = useParams();
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    source: "",
    destination: "",
    time: dayjs(new Date()).format("HH:mm"),
    date: dayjs(new Date()).format("YYYY-MM-DD"),
    driver_uid: "",
  });

  const rideSchema = Yup.object().shape({
    source: Yup.string()
      .min(2, "Source must be atleast two characters.")
      .required("Source is required")
      .test(
        "no-empty-spaces",
        "Source cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    destination: Yup.string()
      .min(2, "Destination must be atleast two characters.")
      .required("Destination is required")
      .test(
        "no-empty-spaces",
        "Destination cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    time: Yup.string()
      .required("Time is required")
      .test(
        "no-empty-spaces",
        "Time cannot be empty spaces",
        (value) => value.trim() !== ""
      )
      .matches(
        /^(0\d|1\d|2[0-3]):([0-5]\d)$/,
        "Invalid time format. Please enter a valid 24-hour time format."
      ),
    date: Yup.string()
      .required("Date is required")
      .test(
        "no-empty-spaces",
        "Date cannot be empty spaces",
        (value) => value.trim() !== ""
      ),

    driver_uid: Yup.string().required("Driver is required"),
  });

  const handlePayDriverClick = () => {
    handlePayment();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const selectDriverValue = (driver_uid: string) => {
    return driverOption.filter((option: any) => {
      return option.value === driver_uid;
    });
  };

  const selectLocationValue = (loc: string) => {
    const location = locationOptions.filter((option: any) => {
      return option.value === loc;
    });
    return location;
  };

  const removeRider = async (index: any) => {
    try {
      const body = {
        id: params.id,
        phone_number: riderArr[index].phone_number,
      };
      const resp = await removeRiderApi(body);

      if (resp?.data?.code === 200) {
        const arr = [...riderArr];
        arr.splice(index, 1);
        setRiderArr(arr);
        toaster.successToast(resp.data?.message);
      } else {
        toaster.errorToast(resp.data?.message);
        throw Error(resp.data?.message || "Something went wrong");
      }
    } catch (error) { }
  };

  const submitRide = async (values: any) => {
    setIsLoading(true);
    try {
      if (params.id) {
        const body = {
          id: params.id,
          driver_uid: values.driver_uid,
          deleteRidersArr: deleteRidersArr,
          driver_payment: driverPaymentStatus,
        };

        const res = await updateRide(body);
        if (res.status === 200 || res.status === 201) {
          toaster.successToast("Ride updated successfully");
          window.history.back();
        } else {
          throw new Error("Something went wrong");
        }
      } else {
        const body = {
          source: values.source,
          destination: values.destination,
          time: values.time,
          date: values.date,
          driver_uid: values.driver_uid,
        };
        const res = await createRide(body);
        if (res.status === 200 || res.status === 201) {
          toaster.successToast("Ride created successfully");
          window.history.back();
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (error: any) {
      toaster.errorToast(
        error?.response?.data?.message ||
        error?.message ||
        "Something went wrong"
      );
    }
    setIsLoading(false);
  };

  const handlePayment = async () => {
    const paymentData: any = {
      amount: paymentAmount,
      name: driverDetail.data.data.first_name,
      upi: driverDetail?.data?.data.Upi_Id,
    };
    const res = await qrcode(paymentData);
    setQrCode(res.data);
  };

  const changeDriverPaymentStatus = () => {
    setDriverPaymentStatus("PAID");
    handleCloseModal();
  };

  useEffect(() => {
    const getDriverFunction = async () => {
      try {
        const res = await getDriverApi();
        if (res.data?.code === 200) {
          const arr = [];
          for (let index = 0; index < res?.data?.data?.length; index++) {
            arr.push({
              value: res?.data?.data[index].uid,
              label: res?.data?.data[index].first_name.concat(
                " ",
                res?.data?.data[index].last_name
              ),
            });
          }
          setDriverOption(arr);
        } else {
          toaster.errorToast(res.data?.message || "Something went wrong");
        }
      } catch (error: any) {
        toaster.errorToast(error.message || "Something went wrong");
      }
    };

    const getRideDetails = async (id: any) => {
      setIsLoading(true);
      let sumTotal = 0;
      try {
        const res: any = await getRide(id);
        if (res?.status === 201 || res?.status === 200) {
          const driverRes: any = await getDriversDetails(res?.data?.driver_id);
          setDriverDetail(driverRes);

          res?.data?.riders.forEach((rider: any) => {
            if (rider.status === "SUCCESS") {
              sumTotal += rider.fare || 0;
            }
          });
          setPaymentAmount(sumTotal);

          setInitialFormValues({
            source: res?.data?.source || "",
            destination: res?.data?.destination || "",
            time: res?.data?.time || "",
            date: res?.data?.date || "",
            // destination_coords_lat: res?.data?.destination_coords[0] || "",
            // destination_coords_long: res?.data?.destination_coords[1] || "",
            // source_coords_lat: res?.data?.source_coords[0] || "",
            // source_coords_long: res?.data?.source_coords[1] || "",
            driver_uid: res?.data?.driver_uid || "",
          });
          setRideStatus(res?.data?.status);
          setRideType(res?.data?.type);
          setRiderArr(res?.data?.riders);
        } else {
          throw new Error(res?.message || "Something went wrong");
        }
      } catch (error: any) {
        toaster.errorToast(
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
        );
      }
      setIsLoading(false);
    };

    const getLocationList = async () => {
      setIsLoading(true);
      try {
        const res: any = await getLocationListApi();
        if (res?.status === 200) {
          setLocationList(res?.data);
          setLocationOptions(
            res.data.map((element: any) => {
              return { value: element.name, label: element.name };
            })
          );
        } else {
          throw new Error(res?.message || "Something went wrong");
        }
      } catch (error: any) {
        toaster.errorToast(
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
        );
      }
      setIsLoading(false);
    };

    if (params.id) {
      getRideDetails(params.id);
    }
    getLocationList();
    getDriverFunction();
  }, []);

  return (
    <>
      <Navbar
        flag={false}
        brandText="ScheduleForm"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
            <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
              {params.id ? (
                <div className="text-xl font-bold text-brand-500 dark:text-white">
                  Edit Ride
                </div>
              ) : (
                <div className="text-xl font-bold text-brand-500 dark:text-white">
                  Add Single Ride
                </div>
              )}
            </header>
            <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
              <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                onSubmit={(values) => {
                  submitRide(values);
                }}
                validationSchema={rideSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex justify-between">
                      <div className="mb-3 me-6 w-full">
                        <div className="input-container">
                          {params.id ? (
                            <input
                              required
                              disabled={true}
                              className={
                                "w-full cursor-not-allowed rounded-xl border border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm text-[#bfbfbf] outline-none"
                              }
                              name="source"
                              value={values?.source}
                            />
                          ) : (
                            <Select
                              isDisabled={false}
                              isSearchable={false}
                              options={locationOptions}
                              onChange={(selectedOption: any) => {
                                setFieldValue("source", selectedOption.value);
                              }}
                              value={selectLocationValue(values.source)}
                              placeholder=""
                              name="source"
                              id="source"
                              styles={{
                                dropdownIndicator: (base: any) => ({
                                  ...base,
                                  marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                                }),
                                menuList: (provided: any) => ({
                                  ...provided,
                                  maxHeight: "200px",
                                }),
                                menu: (provided: any) => ({
                                  ...provided,
                                  maxHeight: "50px",
                                  zIndex: 9999,
                                }),
                                control: (provided: any, state: any) => ({
                                  ...provided,
                                  height: "64px", // Adjust the height as needed
                                  paddingTop: "8px",
                                  paddingLeft: "5px",
                                  borderColor: "#9ca3af",
                                  // This line disable the blue border
                                  boxShadow: state.isFocused
                                    ? "0 0 0 2px #9ca3af"
                                    : "#9ca3af",
                                  "&:hover": {},
                                }),
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  fontSize: "16px",
                                  backgroundColor: state.isSelected
                                    ? "#f2f3f7"
                                    : "white", // Change the background color here
                                  color: "black", // Change the text color here
                                  "&:hover": {
                                    backgroundColor: "#f2f3f7", // Change the background color on hover
                                  },
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          )}
                          <label
                            htmlFor="source"
                            className={`input-custom-label dark:text-white ${values?.source ? "filled" : ""
                              }`}
                          >
                            Source <span className="text-[#ff0000]">*</span>
                          </label>
                        </div>
                        <div className="error-input">
                          {errors.source && touched.source
                            ? errors.source
                            : null}
                        </div>
                      </div>
                      <div className="mb-3 ms-6 w-full">
                        <div className="input-container">
                          {params.id ? (
                            <input
                              required
                              disabled={true}
                              className={
                                "w-full cursor-not-allowed rounded-xl border border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm text-[#bfbfbf] outline-none"
                              }
                              name="destination"
                              value={values?.destination}
                            />
                          ) : (
                            <Select
                              isDisabled={false}
                              isSearchable={false}
                              options={locationOptions}
                              onChange={(selectedOption: any) => {
                                setFieldValue(
                                  "destination",
                                  selectedOption.value
                                );
                              }}
                              value={selectLocationValue(values.destination)}
                              placeholder=""
                              name="destination"
                              id="destination"
                              styles={{
                                dropdownIndicator: (base: any) => ({
                                  ...base,
                                  marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                                }),
                                menuList: (provided: any) => ({
                                  ...provided,
                                  maxHeight: "200px",
                                }),
                                menu: (provided: any) => ({
                                  ...provided,
                                  maxHeight: "50px",
                                  zIndex: 9999,
                                }),
                                control: (provided: any, state: any) => ({
                                  ...provided,
                                  height: "64px", // Adjust the height as needed
                                  paddingTop: "8px",
                                  paddingLeft: "5px",
                                  borderColor: "#9ca3af",
                                  // This line disable the blue border
                                  boxShadow: state.isFocused
                                    ? "0 0 0 2px #9ca3af"
                                    : "#9ca3af",
                                  "&:hover": {},
                                }),
                                option: (provided: any, state: any) => ({
                                  ...provided,
                                  fontSize: "16px",
                                  backgroundColor: state.isSelected
                                    ? "#f2f3f7"
                                    : "white", // Change the background color here
                                  color: "black", // Change the text color here
                                  "&:hover": {
                                    backgroundColor: "#f2f3f7", // Change the background color on hover
                                  },
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          )}

                          <label
                            htmlFor="destination"
                            className={`input-custom-label dark:text-white ${values?.destination ? "filled" : ""
                              }`}
                          >
                            Destination{" "}
                            <span className="text-[#ff0000]">*</span>
                          </label>
                        </div>
                        <div className="error-input">
                          {errors.destination && touched.destination
                            ? errors.destination
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="mb-3 me-6 w-full">
                        <div className="my-2 text-brand-500">
                          Time : <span className="text-[#ff0000]">*</span>
                        </div>
                        <div className="input-container">
                          <TimePicker
                            disabled={params.id ? true : false}
                            allowClear={false}
                            changeOnBlur={true}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            value={dayjs(values.time, "HH:mm")}
                            defaultValue={dayjs("00:00", "HH:mm")}
                            name="time"
                            onChange={(time: Dayjs, timeString: string | string[]) => {
                              setFieldValue("time", timeString);
                            }}
                            format={"HH:mm"}
                          />
                        </div>
                        <div className="error-input">
                          {errors.time && touched.time ? errors.time : null}
                        </div>
                      </div>
                      <div className="mb-3 ms-6 w-full">
                        <div className="my-2 text-brand-500">
                          Date : <span className="text-[#ff0000]">*</span>
                        </div>
                        <div className="input-container">
                          <DatePicker
                            disabled={params.id ? true : false}
                            allowClear={false}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            value={dayjs(values.date, "YYYY-MM-DD")}
                            name="date"
                            onChange={(date, dateString) => {
                              setFieldValue("date", dateString);
                            }}
                          />
                        </div>
                        <div className="error-input">
                          {errors.date && touched.date ? errors.date : null}
                        </div>
                      </div>
                    </div>

                    {/* <div className='my-2 text-brand-500'>Source Co-ordinates :</div>
                                        <div className="flex justify-between">
                                            <div className="mb-3 me-6 w-full">
                                                <div className="input-container">
                                                    <input
                                                        required
                                                        disabled={params.id ? true : false}
                                                        className={params.id ? "w-full rounded-xl border text-[#bfbfbf] border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm outline-none cursor-not-allowed" : "w-full rounded-xl border border-[#9ca3af] bg-white/0 p-3 text-sm outline-none"}
                                                        name="source_coords_lat"
                                                        type="number"
                                                        id="source_coords_lat"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values?.source_coords_lat}
                                                    />
                                                    <label
                                                        htmlFor="source_coords_lat"
                                                        className={`input-custom-label dark:text-white ${values?.source_coords_lat ? "filled" : ""
                                                            }`}
                                                    >
                                                        Latitude <span className='text-[#ff0000]'>*</span>
                                                    </label>
                                                </div>
                                                <div className="error-input">
                                                    {errors.source_coords_lat && touched.source_coords_lat ? errors.source_coords_lat : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 ms-6 w-full">
                                                <div className="input-container">
                                                    <input
                                                        required
                                                        disabled={params.id ? true : false}
                                                        className={params.id ? "w-full rounded-xl border text-[#bfbfbf] border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm outline-none cursor-not-allowed" : "w-full rounded-xl border border-[#9ca3af] bg-white/0 p-3 text-sm outline-none"}
                                                        name="source_coords_long"
                                                        type="number"
                                                        id="source_coords_long"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values?.source_coords_long}
                                                    />
                                                    <label
                                                        htmlFor="source_coords_long"
                                                        className={`input-custom-label dark:text-white ${values?.source_coords_long ? "filled" : ""
                                                            }`}
                                                    >
                                                        Longitude <span className='text-[#ff0000]'>*</span>
                                                    </label>
                                                </div>
                                                <div className="error-input">
                                                    {errors.source_coords_long && touched.source_coords_long ? errors.source_coords_long : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='my-2 text-brand-500'>Destination Co-ordinates :</div>
                                        <div className="flex justify-between">
                                            <div className="mb-3 me-6 w-full">
                                                <div className="input-container">
                                                    <input
                                                        required
                                                        disabled={params.id ? true : false}
                                                        className={params.id ? "w-full rounded-xl border text-[#bfbfbf] border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm outline-none cursor-not-allowed" : "w-full rounded-xl border border-[#9ca3af] bg-white/0 p-3 text-sm outline-none"}
                                                        name="destination_coords_lat"
                                                        type="number"
                                                        id="destination_coords_lat"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values?.destination_coords_lat}
                                                    />
                                                    <label
                                                        htmlFor="destination_coords_lat"
                                                        className={`input-custom-label dark:text-white ${values?.destination_coords_lat ? "filled" : ""
                                                            }`}
                                                    >
                                                        Latitude <span className='text-[#ff0000]'>*</span>
                                                    </label>
                                                </div>
                                                <div className="error-input">
                                                    {errors.destination_coords_lat && touched.destination_coords_lat ? errors.destination_coords_lat : null}
                                                </div>
                                            </div>
                                            <div className="mb-3 ms-6 w-full">
                                                <div className="input-container">
                                                    <input
                                                        required
                                                        disabled={params.id ? true : false}
                                                        className={params.id ? "w-full rounded-xl border text-[#bfbfbf] border-[#d9d9d9] bg-[#000000]/[0.04] p-3 text-sm outline-none cursor-not-allowed" : "w-full rounded-xl border border-[#9ca3af] bg-white/0 p-3 text-sm outline-none"}
                                                        name="destination_coords_long"
                                                        type="number"
                                                        id="destination_coords_long"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values?.destination_coords_long}
                                                    />
                                                    <label
                                                        htmlFor="destination_coords_long"
                                                        className={`input-custom-label dark:text-white ${values?.destination_coords_long ? "filled" : ""
                                                            }`}
                                                    >
                                                        Longitude <span className='text-[#ff0000]'>*</span>
                                                    </label>
                                                </div>
                                                <div className="error-input">
                                                    {errors.destination_coords_long && touched.destination_coords_long ? errors.destination_coords_long : null}
                                                </div>
                                            </div>
                                        </div> */}

                    <div className="flex justify-between">
                      <div className="mb-3 me-6 w-full">
                        <div className="input-container">
                          <Select
                            isSearchable={false}
                            options={driverOption}
                            onChange={(selectedOption: any) => {
                              setFieldValue("driver_uid", selectedOption.value);
                            }}
                            value={selectDriverValue(values.driver_uid)}
                            placeholder=""
                            name="driver_uid"
                            id="driver_uid"
                            styles={{
                              dropdownIndicator: (base: any) => ({
                                ...base,
                                marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                              }),
                              menuList: (provided: any) => ({
                                ...provided,
                                maxHeight: "200px",
                              }),
                              menu: (provided: any) => ({
                                ...provided,
                                maxHeight: "50px",
                                zIndex: 9999,
                              }),
                              control: (provided: any, state: any) => ({
                                ...provided,
                                height: "64px", // Adjust the height as needed
                                paddingTop: "8px",
                                paddingLeft: "5px",
                                borderColor: "#9ca3af",
                                // This line disable the blue border
                                boxShadow: state.isFocused
                                  ? "0 0 0 2px #9ca3af"
                                  : "#9ca3af",
                                "&:hover": {},
                              }),
                              option: (provided: any, state: any) => ({
                                ...provided,
                                fontSize: "16px",
                                backgroundColor: state.isSelected
                                  ? "#f2f3f7"
                                  : "white", // Change the background color here
                                color: "black", // Change the text color here
                                "&:hover": {
                                  backgroundColor: "#f2f3f7", // Change the background color on hover
                                },
                              }),
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                          <label
                            htmlFor="driver_uid"
                            className={`input-custom-label dark:text-white ${values?.driver_uid ? "filled" : ""
                              }`}
                          >
                            Driver <span className="text-[#ff0000]">*</span>
                          </label>
                        </div>
                        <div className="error-input">
                          {errors.driver_uid && touched.driver_uid
                            ? errors.driver_uid
                            : null}
                        </div>
                      </div>
                      <div className="mb-3 ms-6 w-full"></div>
                    </div>

                    {rideType == "POOL_RIDE" &&
                      rideStatus == "completed" &&
                      driverDetail?.data?.data?.Upi_Id &&
                      isModalOpen && (
                        <div className="modal-overlay">
                          <div className="modal-container">
                            <div className="flex flex-col items-center justify-center">
                              <p
                                className="ml-auto cursor-pointer text-2xl font-bold"
                                onClick={handleCloseModal}
                              >
                                X
                              </p>
                              <p className="mb-2 text-2xl font-bold">
                                Payment Details
                              </p>
                              <p className="text-lg">
                                <span className="font-bold">RideId :</span>{" "}
                                {params.id}
                              </p>
                              <p className="mb-3 text-lg">
                                <span className="font-bold">Driver :</span>{" "}
                                {driverDetail?.data?.data?.first_name}{" "}
                                {driverDetail?.data?.data?.last_name}
                              </p>
                              <p className="text-xl font-bold">
                                Amount: {paymentAmount}
                              </p>
                              <p>Scan this QR to pay driver</p>
                              {qrCode && (
                                <img
                                  src={qrCode}
                                  alt="QR Code"
                                  className="mb-3"
                                />
                              )}
                              <button
                                type="button"
                                onClick={changeDriverPaymentStatus}
                                className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                              >
                                PAID
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    {riderArr.length > 0 && (
                      <>
                        <div className="flex justify-between">
                          <div className="my-2 text-brand-500">Riders :</div>
                          {rideType == "POOL_RIDE" &&
                            rideStatus == "completed" &&
                            driverDetail?.data?.data?.Upi_Id && (
                              <>
                                <button
                                  type="button"
                                  className="w-1/3 cursor-pointer self-end rounded bg-navy-500 px-3 py-2 font-bold text-white"
                                  onClick={handlePayDriverClick}
                                >
                                  Pay Driver
                                </button>
                              </>
                            )}
                        </div>
                        <div>
                          <table className="mt-6 w-full rounded-xl bg-white shadow-md">
                            <thead>
                              <tr>
                                <th className="p-2 font-semibold">Name</th>
                                <th className="p-2 font-semibold">
                                  Phone Number
                                </th>
                                <th className="p-2 font-semibold">Fare</th>
                                <th className="p-2 font-semibold">
                                  Payment Mode
                                </th>
                                <th className="p-2 font-semibold">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {riderArr.map((rider, index) => {
                                return (
                                  <tr>
                                    <td className="p-2 text-center">
                                      {rider.name}
                                    </td>
                                    <td className="p-2 text-center">
                                      {rider.phone_number}
                                    </td>
                                    <td className="p-2 text-center">
                                      {rider?.fare && rider?.fare}
                                    </td>
                                    <td className="p-2 text-center">
                                      {rider?.payment_mode &&
                                        rider?.payment_mode}
                                    </td>
                                    <td className="p-2 text-center">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          removeRider(index);
                                        }}
                                      >
                                        <img
                                          className="h-100 me-2 cursor-pointer"
                                          src={deleteIcon}
                                        ></img>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {/* <Card extra='w-full '>
                               <div className="flex items-center space-x-4 mt-6 bg-white p-2 rounded-xl shadow-md">
                                 <div className=" block">
                                  {" "}
                                   <span className="font-semibold">
                                     Name :
                                   </span>{" "}
                                   {rider.name}
                                 </div>{" "}
                                 <div className=" block">
                                   {" "}
                                   <span className="font-semibold">
                                     Phone :
                                   </span>{" "}
                                   {rider.phone_number}
                                 </div>{" "}
                                  <div className=" block">
                                   {" "}
                                   <span className="font-semibold">
                                     Fare :
                                   </span>{" "}
                                   {rider?.fare && rider?.fare}
                                 </div>{" "}
                                  <div className=" block">
                                   {" "}
                                   <span className="font-semibold">
                                     Payment Mode :
                                   </span>{" "}
                                   {rider?.payment_mode && rider?.payment_mode}
                                 </div>{" "}
                                 <button
                                  type="button"
                                  onClick={() => {
                                    removeRider(index);
                                  }}
                                  className="w-1/2 cursor-pointer self-end rounded bg-red-500 px-3 py-2 font-bold text-white"
                                >
                                  <img
                                    className="inline-block w-[23px] "
                                    src={crossIcon}
                                    alt="delete-icon"
                                  ></img>{" "}
                                  Delete{" "}
                                </button>{" "}
                              </div>
                              </Card> */}
                        </div>
                      </>
                    )}

                    {!isModalOpen && (
                      <div className="button-save-cancel mt-3 flex justify-end">
                        <Button
                          className=" cancel-button my-2 ms-1 sm:my-0"
                          onClick={() => {
                            window.history.back();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                        >
                          {params.id ? "Update Ride" : "Add Ride"}
                        </Button>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default RideForm;
