import axios from "axios";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const globalAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const requestHandler = (request) => {
  const token = localStorage.getItem("token");
  if (!token ) {
    if (window) {
      window.location.href = "/";
    }
    return Promise.reject("No token available");
  }else{
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  }
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === 403 || error?.response?.status === 401) {
    localStorage.clear();
    window.location.href = "/admin/signin";
    
  }
  return error;
};

customAxios.interceptors.request.use((request) => {
  return requestHandler(request);
});

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;