import customAxios, { globalAxios } from "./appservices";

export const getDriversList = (data) => {
  return customAxios.post("/admin/get-driver-list", data);
};

export const approveHandleApi = (id) => {
  return customAxios.post("/admin/driver-verification-approve", { id });
};

export const getDriversDetails = (id) => {
  return customAxios.post("/admin/get-driver-details", { id });
};

export const updateDriverApi = (body) => {
  return customAxios.patch("/driver", body);
};

export const getPreSignedUrlUpload = (body) => {
  return customAxios.post("/driver/upload-signed-url", body);
};

export const deleteObjectFromS3Api = (key) => {
  return customAxios.post("/driver/delete-object-from-s3", key);
};

export const getDriverApi = () => {
  return customAxios.get("admin/get-verified-drivers");
};

export const getRide = (id) => {
  return customAxios.get(`/ride/${id}`);
};

export const updateRide = (body) => {
  return customAxios.patch("/ride/admin-update", body);
};

export const handleLoginApi = (data) => {
  return globalAxios.post("/admin/login", data);
};

export const createRide = (body) => {
  return customAxios.post("/ride", body);
};

export const createMany = (arr) => {
  return customAxios.post("/ride/bulk", arr);
};

export const createBulkLocation = (arr) => {
  return customAxios.post("/location/create-bulk", arr);
};

export const getRidesList = (data) => {
  return customAxios.post("/admin/get-ride-list", data);
};

export const getRidersList = (data) => {
  return customAxios.post("/admin/get-rider-list", data);
};

export const deleteRideApi = (id) => {
  return customAxios.delete(`/ride/delete/${id}`);
};

export const removeRiderApi = (body) => {
  return customAxios.post(`/ride/remove-rider`, body);
};

export const getLocationListApi = () => {
  return customAxios.get("/location/");
};

export const getLocationListWithPaginationApi = (data) => {
  return customAxios.post("/location/get-location-list", data);
};

export const createLoctionApi = (data) => {
  return customAxios.post("/location/", data);
};

export const getLoctionApi = (id) => {
  return customAxios.get(`/location/${id}`);
};

export const qrcode = async (data) => {
  return customAxios.post(`/payment/generate-qr-code`, data);
};

//open
export const handleRegisterApi = (data) => {
  return "dummy";
};

//superAdmin
export const handleChangePasswordApi = (data) => {
  return;
};

//superAdmin
export const getUserDetailsApi = (id) => {
  return;
};

//superAdmin
export const getAllUserVideosApi = (id) => {
  return;
};

//superAdmin
export const deleteAdminApi = (data) => {
  return;
};

// //superAdmin
// export const getPaginatedAdminDataApi = (data) => {
//   return customAxios.post(`/paginatedAdminData`, data);
// };

//both
export const handleCreateAdminApi = (id, data) => {
  return;
};

//superAdmin
export const createAdminApi = (data) => {
  return;
};

//both
export const getAdminByIdApi = (id) => {
  //console.log("Params1>>", id);
  return;
};

//superAdmin
export const getAllUser = (data) => {
  return;
};

//superAdmin
export const getAllAdmin = (data) => {
  return;
};

//both
export const handleUpdateCategoryApi = (id, data) => {
  return;
};

//both
export const createCategoryApi = (data) => {
  return;
};

//both
export const getCategoryByIdApi = (id) => {
  return;
};

//both
export const getAllCategories = (data) => {
  //console.log("object data :>> ", data);
  return;
};

//both
export const deleteCategoryHandleApi = (info) => {
  //console.log("object info :>> ", info);
  return;
};

//both
export const getAllSkills = (data) => {
  //console.log("object data :>> ", data);
  return;
};

//both
export const deleteSkillHandleApi = (info) => {
  //console.log("object info :>> ", info);
  return;
};

//both
export const handleUpdateSkillApi = (id, data) => {
  return;
};

//both
export const createSkillApi = (data) => {
  return;
};

//both
export const getSkillByIdApi = (id) => {
  return;
};

//both
export const getAllVideos = (data) => {
  return;
};

//both
export const deleteVideoHandleApi = (info) => {
  return;
};

//both
export const getS3SignUrlApi = (data, headers) => {
  return;
};

//both
export const getAllSkillsApi = () => {
  return;
};

//both
export const getAllCategoryByTagApi = (tag) => {
  return;
};

//both
export const createVideoApi = (values) => {
  return;
};

//both
export const getVideoDetailsById = (id) => {
  return;
};

//both
export const handleUpdateVideoApi = (id, body) => {
  return;
};

//both
export const getAuthors = () => {
  return;
};

//superAdmin
export const getDashboardDataApi = () => {
  return;
};

//superAdmin
export const getRevenueDataApi = (data) => {
  return;
};
