import { Button, ChakraProvider, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import deleteIcon from "../../../../assets/svg/deleteIcon.svg";
import "../ridelist.css";

const CustomModal = (props: any) => {
    const { isOpen, onClose, source, destination, id, deleteHandle } = props
    return (
        <ChakraProvider>
            <Modal
                isCentered={true}
                isOpen={isOpen}
                onClose={onClose}
                size="md"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <div className="mb-2 flex justify-center">
                        <img
                            src={deleteIcon}
                        />
                    </div>
                    <ModalBody className="text-center">
                        Are you sure you want to Delete this ride<br />
                        from {source} to {destination}
                    </ModalBody>

                    <div className="mt-3 flex justify-center">
                        <Button
                            className="cancel-delete-modal-button mx-2"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="delete-modal-button mx-2"
                            onClick={() => deleteHandle(id)}
                        >
                            Delete
                        </Button>
                    </div>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    )
}

export default CustomModal