import Loader from "components/loader/loader";
import Navbar from "components/navbar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../../../components/card";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "@chakra-ui/react";
import "./locationForm.css";
import { toaster } from "components/common/toaster";
import { createLoctionApi, getLoctionApi } from "services/customAPI";

type formvalues = {
  name: string;
  location_link: string;
  lat: number;
  long: number;
};

const LocationForm = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    name: "",
    location_link: "",
    lat: 0,
    long: 0,
  });
  const coordinateValidation = (value: any) => {
    // Check if value has a dot after the second digit
    return /^\d{2}\.[0-9]+$/.test(value);
  };

  const LocationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Location must be atleast two characters.")
      .required("Location is required")
      .test(
        "no-empty-spaces",
        "Location cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    location_link: Yup.string()
      .min(2, "Link must be atleast two characters.")
      .required("Link is required")
      .test(
        "no-empty-spaces",
        "Link cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    lat: Yup.string()
      .required("Location latitude is required")
      .test(
        "no-empty-spaces",
        "Location latitude cannot be empty spaces",
        (value) => value.trim() !== ""
      )
      .test(
        "coordinate-format",
        "Latitude should have decimal point after two digits",
        coordinateValidation
      ),
    long: Yup.string()
      .required("Location latitude is required")
      .test(
        "no-empty-spaces",
        "Source latitude cannot be empty spaces",
        (value) => value.trim() !== ""
      )
      .test(
        "coordinate-format",
        "Latitude should have decimal point after two digits",
        coordinateValidation
      ),
  });

  const submitLocation = async (values: any) => {
    setIsLoading(true);
    try {
      const body = {
        name: values.name,
        location_link: values.location_link,
        lat: Number(values.lat),
        long: Number(values.long),
      };
      const res = await createLoctionApi(body);
      if (res.status === 201) {
        toaster.successToast("Location created successfully");
        window.history.back();
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error: any) {
      toaster.errorToast(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getLocationDetails = async (id: any) => {
      setIsLoading(true);
      try {
        const res: any = await getLoctionApi(id.toString());
        if (res?.status === 201 || res?.status === 200) {
          setInitialFormValues({
            name: res?.data?.name || "",
            location_link: res?.data?.location_link || "",
            lat: res?.data?.lat || "",
            long: res?.data?.long || "",
          });
        } else {
          throw new Error(res?.message || "Something went wrong");
        }
      } catch (error: any) {
        toaster.errorToast(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong"
        );
      }
      setIsLoading(false);
    };

    if (params.id) {
      getLocationDetails(params.id);
    }
  }, [params.id]);

  return (
    <>
      <Navbar
        flag={false}
        brandText="LocationForm"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
            <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
              {params.id ? (
                <div className="text-xl font-bold text-brand-500 dark:text-white">
                  View Location
                </div>
              ) : (
                <div className="text-xl font-bold text-brand-500 dark:text-white">
                  Add New Location
                </div>
              )}
            </header>
            <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
              <Formik
                enableReinitialize={true}
                initialValues={initialFormValues}
                onSubmit={(values) => {
                  submitLocation(values);
                }}
                validationSchema={LocationSchema}
              >
                {({ handleSubmit, values, errors, touched, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="flex justify-between">
                      <div className="mb-3 me-6 w-full">
                        <div className="input-container">
                          <label
                            htmlFor="name"
                            className={`input-custom-label dark:text-white ${
                              values?.name ? "filled" : ""
                            }`}
                          >
                            Name <span className="text-[#ff0000]">*</span>
                          </label>
                          <Field
                            disabled={params.id ? true : false}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            name="name"
                          />
                        </div>
                        <div className="error-input">
                          {errors.name && touched.name ? errors.name : null}
                        </div>
                      </div>
                      <div className="mb-3 ms-6 w-full">
                        <div className="input-container">
                          <label
                            htmlFor="location_link"
                            className={`input-custom-label dark:text-white ${
                              values?.location_link ? "filled" : ""
                            }`}
                          >
                            Link <span className="text-[#ff0000]">*</span>
                          </label>
                          <Field
                            disabled={params.id ? true : false}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            name="location_link"
                          />
                        </div>
                        <div className="error-input">
                          {errors.location_link && touched.location_link
                            ? errors.location_link
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="mb-3 me-6 w-full">
                        <div className="my-2 text-brand-500">
                          Latitude : <span className="text-[#ff0000]">*</span>
                        </div>
                        <div className="input-container">
                          <Field
                            disabled={params.id ? true : false}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            name="lat"
                          />
                        </div>
                        <div className="error-input">
                          {errors.lat && touched.lat ? errors.lat : null}
                        </div>
                      </div>
                      <div className="mb-3 ms-6 w-full">
                        <div className="my-2 text-brand-500">
                          Longitude : <span className="text-[#ff0000]">*</span>
                        </div>
                        <div className="input-container">
                          <Field
                            disabled={params.id ? true : false}
                            className="border border-[#9ca3af] px-4 py-[20px]"
                            name="long"
                          />
                        </div>
                        <div className="error-input">
                          {errors.long && touched.long ? errors.long : null}
                        </div>
                      </div>
                    </div>
                    {!params.id ? (
                      <div className="button-save-cancel mt-3 flex justify-end">
                        <Button
                          className=" cancel-button my-2 ms-1 sm:my-0"
                          onClick={() => {
                            window.history.back();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                        >
                          Add
                        </Button>
                      </div>
                    ) : (
                      <div className="button-save-cancel mt-3 flex justify-end">
                        <Button
                          className=" cancel-button my-2 ms-1 sm:my-0"
                          onClick={() => {
                            window.history.back();
                          }}
                        >
                          Back
                        </Button>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default LocationForm;
