import Loader from "components/loader/loader";
import Navbar from "components/navbar";
import React, { useEffect, useRef, useState } from "react";
import { Router, useNavigate, useParams } from "react-router-dom";
import Card from "../../../../components/card";
import crossIcon from "assets/svg/whiteBorderCrossBtn.svg";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Button, Divider } from "@chakra-ui/react";
import "./driverForm.css";
import {
  deleteObjectFromS3Api,
  getDriversDetails,
  getPreSignedUrlUpload,
  updateDriverApi,
} from "services/customAPI";
import { CiSaveDown2 } from "react-icons/ci";
import { toaster } from "components/common/toaster";
import axios from "axios";

type formvalues = {
  first_name: string;
  last_name: string;
  pool: boolean;
  phone_number: string;
  approval: string;
  license: any;
  car_image: any;
  aadhar: any;
  seats: number;
};

const DriverDetails = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [approvalState, setApprovalState] = useState("");
  const params = useParams();
  const [licensePreview, setLicensePreview] = useState({
    license: null,
    key: "",
  });
  const [aadharPreview, setAadharPreview] = useState({
    aadhar: null,
    key: "",
  });
  const [carPreview, setCarPreview] = useState({
    car: null,
    key: "",
  });
  const [initialFormValues, setInitialFormValues] = useState<formvalues>({
    first_name: "",
    last_name: "",
    pool: false,
    phone_number: "",
    approval: "",
    license: null,
    car_image: null,
    aadhar: null,
    seats: 0,
  });
  const licenseRef = useRef(null);
  const aadharRef = useRef(null);
  const carRef = useRef(null);
  const [isValidation, setIsValidation] = useState({
    license: true,
    car_image: true,
    aadhar: true,
  });

  const ApprovalOption = [
    { value: "Accept", label: "Accept" },
    { value: "Reject", label: "Reject" },
  ];

  const FILE_SIZE = 1024 * 1024;
  const SUPPORTED_FORMATS_image = ["image/jpg", "image/jpeg", "image/png"];

  const driverSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "First name must be atleast two characters.")
      .required("First name is required")
      .test(
        "no-empty-spaces",
        "First name cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    last_name: Yup.string()
      .min(2, "Last name must be atleast two characters.")
      .required("Last name is required")
      .test(
        "no-empty-spaces",
        "Last name cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    phone_number: Yup.string()
      .min(10, "phone number must be at least 10 characters")
      .max(10, "phone number must be at most 10 characters")
      .required("phone number is required")
      .test(
        "no-empty-spaces",
        "phone number cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    approval: Yup.string()
      .required("approval is required")
      .test(
        "no-empty-spaces",
        "approval cannot be empty spaces",
        (value) => value.trim() !== ""
      ),
    license: isValidation.license
      ? Yup.mixed()
          .required("license is required")
          .test("fileSize", "File too large", (value: any) => {
            return value && value.size <= FILE_SIZE;
          })
          .test(
            "fileFormat",
            "Unsupported Format hi",
            (value: any) =>
              value && SUPPORTED_FORMATS_image.includes(value.type)
          )
      : null,
    car_image: isValidation.car_image
      ? Yup.mixed()
          .required("Car Image is required")
          .test("fileSize", "File too large", (value: any) => {
            return value && value.size <= FILE_SIZE;
          })
          .test(
            "fileFormat",
            "Unsupported Format",
            (value: any) =>
              value && SUPPORTED_FORMATS_image.includes(value.type)
          )
      : null,
    aadhar: isValidation.aadhar
      ? Yup.mixed()
          .required("Aadhar Image is required")
          .test("fileSize", "File too large", (value: any) => {
            return value && value.size <= FILE_SIZE;
          })
          .test(
            "fileFormat",
            "Unsupported Format",
            (value: any) =>
              value && SUPPORTED_FORMATS_image.includes(value.type)
          )
      : null,
  });

  const downloadImage = (imageURL: any) => {
    // Create a link element
    const link = document.createElement("a");
    link.href = imageURL;

    // Set the download attribute and filename
    link.download = "downloaded_image.jpg";
    link.target = "_blank";

    // Trigger the download
    link.click();
  };

  const updateDriver = async (values: any) => {
    setIsLoading(true);
    const documents = [];
    let licenseResponse: any, carImageResponse: any, aadharResponse: any;
    try {
      if (values.license) {
        //upload license image to s3 and delete previous key
        licenseResponse = await getPreSignedUrlUpload({
          driver_phone_number: String(values.phone_number),
          content_type: values.license.type,
          document_name: values.license.name,
        });
        const uploadObject = await axios.put(
          licenseResponse?.data?.url,
          values.license
        );
        if (uploadObject.status !== 200) {
          throw new Error("Error while uploading license");
        }
        documents.push({
          document_name: "license_card",
          document_key: licenseResponse?.data?.key,
        });
        if (licensePreview.key && uploadObject.status === 200) {
          try {
            const deleteObject = await deleteObjectFromS3Api({
              key: licensePreview.key,
            });
          } catch (error) {
            console.log(error, "license");
          }
        }
      } else {
        documents.push({
          document_name: "license_card",
          document_key: licensePreview.key,
        });
      }
      if (values.car_image) {
        //upload car_imageto s3 and delete previous key

        carImageResponse = await getPreSignedUrlUpload({
          driver_phone_number: String(values.phone_number),
          content_type: values.car_image.type,
          document_name: values.car_image.name,
        });
        const uploadObject = await axios.put(
          carImageResponse?.data?.url,
          values.car_image
        );
        if (uploadObject.status !== 200) {
          throw new Error("Error while uploading car image");
        }
        documents.push({
          document_name: "car_image",
          document_key: carImageResponse?.data?.key,
        });

        if (carPreview.key && uploadObject.status === 200) {
          try {
            const deleteObject = await deleteObjectFromS3Api({
              key: carPreview.key,
            });
          } catch (error) {
            console.log(error, "car_image");
          }
        }
      } else {
        documents.push({
          document_name: "car_image",
          document_key: carPreview.key,
        });
      }
      if (values.aadhar) {
        //upload aadhar s3 and delete previous key
        aadharResponse = await getPreSignedUrlUpload({
          driver_phone_number: String(values.phone_number),
          content_type: values.aadhar.type,
          document_name: values.aadhar.name,
        });
        const uploadObject = await axios.put(
          aadharResponse?.data?.url,
          values.aadhar
        );
        documents.push({
          document_name: "aadhar_image",
          document_key: aadharResponse?.data?.key,
        });
        if (aadharPreview.key && uploadObject.status === 200) {
          try {
            const deleteObject = await deleteObjectFromS3Api({
              key: aadharPreview.key,
            });
          } catch (error) {
            console.log(error, "aadhar");
          }
        }
      } else {
        documents.push({
          document_name: "aadhar_image",
          document_key: aadharPreview.key,
        });
      }

      const body = {
        id: params.id,
        first_name: values.first_name,
        last_name: values.last_name,
        pool: values.pool,
        phone_number: String(values.phone_number),
        verified: values.approval === "Accept" ? true : false,
        documents: documents,
        no_of_seat: values.seats,
      };

      const response: any = await updateDriverApi(body);

      if (response) {
        toaster.successToast("Driver updated successfully");
        window.history.back();
      } else {
        throw new Error(response.message || "Something went wrong");
      }
    } catch (error: any) {
      toaster.errorToast(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  const getDriverDetails = async () => {
    setIsLoading(true);
    try {
      const response: any = await getDriversDetails(params.id);
      if (response.data?.code === 200) {
        setApprovalState(response?.data?.data?.verified ? "Accept" : "Reject");
        let license_url, car_image_url, aadhar_url;
        for (
          let index = 0;
          index < response?.data?.data?.documents?.length;
          index++
        ) {
          if (
            response?.data?.data?.documents?.[index].document_name ===
            "license_card"
          ) {
            license_url = response?.data?.data?.documents?.[index].url;
            setLicensePreview({
              license: license_url,
              key: response?.data?.data?.documents?.[index].key,
            });
            setIsValidation((prev: any) => ({
              ...prev,
              license: false,
            }));
          } else if (
            response?.data?.data?.documents?.[index].document_name ===
            "car_image"
          ) {
            car_image_url = response?.data?.data?.documents?.[index].url;
            setCarPreview({
              car: car_image_url,
              key: response?.data?.data?.documents?.[index].key,
            });
            setIsValidation((prev: any) => ({
              ...prev,
              car_image: false,
            }));
          } else if (
            response?.data?.data?.documents?.[index].document_name ===
            "aadhar_image"
          ) {
            aadhar_url = response?.data?.data?.documents?.[index].url;
            setAadharPreview({
              aadhar: aadhar_url,
              key: response?.data?.data?.documents?.[index].key,
            });
            setIsValidation((prev: any) => ({
              ...prev,
              aadhar: false,
            }));
          }
        }
        setInitialFormValues({
          first_name: response?.data?.data?.first_name || "",
          last_name: response?.data?.data?.last_name || "",
          pool: response?.data?.data?.pool || false,
          phone_number: response?.data?.data?.phone_number || "",
          approval:
            (response?.data?.data?.verified ? "Accept" : "Reject") || "",
          license: null,
          car_image: null,
          aadhar: null,
          seats: response?.data?.data?.no_of_seat,
        });
      } else {
        throw new Error(response.data?.message);
      }
    } catch (error: any) {
      toaster.errorToast(error?.message || "Something went wrong");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (params.id) {
      getDriverDetails();
    }
  }, []);

  return (
    <>
      <Navbar
        flag={false}
        brandText="DriverForm"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Card extra={"w-full pb-6 p-4 h-full mt-4 pt-10"}>
          <header className="relative flex items-center justify-between ps-[10%] sm:ps-20">
            <div className="text-xl font-bold text-brand-500 dark:text-white">
              Edit Driver
            </div>
          </header>
          <div className="p-6 sm:p-10 sm:pb-5 sm:pe-20 sm:ps-20">
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              onSubmit={(values) => {
                updateDriver(values);
              }}
              validationSchema={driverSchema}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="flex justify-between gap-5">
                    <div className="mb-3 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="first_name"
                          type="text"
                          id="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.first_name}
                        />
                        <label
                          htmlFor="first_name"
                          className={`input-custom-label dark:text-white ${
                            values?.first_name ? "filled" : ""
                          }`}
                        >
                          First Name
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.first_name && touched.first_name
                          ? errors.first_name
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="last_name"
                          type="text"
                          id="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.last_name}
                        />
                        <label
                          htmlFor="last_name"
                          className={`input-custom-label dark:text-white ${
                            values?.last_name ? "filled" : ""
                          }`}
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.last_name && touched.last_name
                          ? errors.last_name
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 w-full flex justify-center items-center gap-2">
                        <input
                          className=" rounded-xl border bg-white/0 p-3 text-sm outline-none transform scale-150"
                          name="pool"
                          type="checkbox"
                          id="pool"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values?.pool}
                        />
                        <label
                          htmlFor="poolDriver"
                          className="ms-2 dark:text-white text-lg font-bold"
                        >
                          Pool Driver
                        </label>
                    </div>
                  </div>

                  <div className="flex justify-between gap-5">
                    <div className="mb-3 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="phone_number"
                          type="number"
                          id="phone_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.phone_number}
                        />
                        <label
                          htmlFor="phone_number"
                          className={`input-custom-label dark:text-white ${
                            values?.phone_number ? "filled" : ""
                          }`}
                        >
                          Phone Number
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.phone_number && touched.phone_number
                          ? errors.phone_number
                          : null}
                      </div>
                    </div>
                    <div className="mb-3 w-full">
                      <div className="input-container">
                        <input
                          required
                          className="w-full rounded-xl border bg-white/0 p-3 text-sm outline-none"
                          name="seats"
                          type="number"
                          id="seats"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.seats}
                        />
                        <label
                          htmlFor="phone_number"
                          className={`input-custom-label dark:text-white ${
                            values?.seats ? "filled" : ""
                          }`}
                        >
                          Seats
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.seats && touched.seats ? errors.seats : null}
                      </div>
                    </div>
                    <div className="mb-3 w-full">
                      <div className="input-container">
                        <Select
                          isSearchable={false}
                          options={ApprovalOption}
                          onChange={(selectedOption) => {
                            setApprovalState(selectedOption.value);
                            values.approval = selectedOption.value;
                            setFieldValue("approval", selectedOption.value);
                          }}
                          value={ApprovalOption.filter(function (option: any) {
                            return option.value == approvalState;
                          })}
                          placeholder=""
                          name="approval"
                          id="approval"
                          styles={{
                            dropdownIndicator: (base: any) => ({
                              ...base,
                              marginTop: "-24px", // Add padding to the bottom of the dropdown indicator
                            }),
                            menu: (provided: any) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (provided: any, state: any) => ({
                              ...provided,
                              height: "64px", // Adjust the height as needed
                              paddingTop: "8px",
                              paddingLeft: "5px",
                              borderColor: "#9ca3af",
                              // This line disable the blue border
                              boxShadow: state.isFocused
                                ? "0 0 0 2px #9ca3af"
                                : "#9ca3af",
                              "&:hover": {},
                            }),
                            option: (provided: any, state: any) => ({
                              ...provided,
                              fontSize: "16px",
                              backgroundColor: state.isSelected
                                ? "#f2f3f7"
                                : "white", // Change the background color here
                              color: "black", // Change the text color here
                              "&:hover": {
                                backgroundColor: "#f2f3f7", // Change the background color on hover
                              },
                            }),
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label
                          htmlFor="Approval"
                          className={`input-custom-label dark:text-white ${
                            values?.approval ? "filled" : ""
                          }`}
                        >
                          Approval
                        </label>
                      </div>
                      <div className="error-input">
                        {errors.approval && touched.approval
                          ? errors.approval
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="mb-3 me-6 w-full">
                      <div className="mb-2 text-[16px] text-[#6f81a5]">
                        License Image:
                      </div>
                      {licensePreview.license || values.license ? (
                        <>
                          <div className="relative mr-4 flex h-[120px] w-full justify-center rounded-[4px] border border-[#9CA3AF] sm:h-[200px]">
                            <button
                              type="button"
                              onClick={(e) => {
                                setLicensePreview((prev) => ({
                                  ...prev,
                                  license: null,
                                }));
                                values.license = null;
                                setIsValidation((prev: any) => ({
                                  ...prev,
                                  license: true,
                                }));
                              }}
                              className="absolute right-0 top-0 z-10"
                            >
                              <img src={crossIcon}></img>
                            </button>
                            <img
                              src={
                                licensePreview.license ||
                                URL.createObjectURL(values.license)
                              }
                              style={{
                                objectFit: "contain",
                                height: "100%",
                                width: "auto",
                                cursor: "pointer",
                              }}
                              alt="img"
                            />
                          </div>
                          <div
                            className="my-2 inline-block"
                            onClick={() => {
                              downloadImage(
                                licensePreview.license ||
                                  URL.createObjectURL(values.license)
                              );
                            }}
                          >
                            <CiSaveDown2 size={24} className="cursor-pointer" />
                          </div>
                          <ErrorMessage
                            name="license"
                            component="div"
                            className="error-input"
                          />
                        </>
                      ) : (
                        <div>
                          <div
                            onClick={() => {
                              licenseRef.current.click();
                            }}
                            className="relative mr-4 h-[120px] w-full rounded-[8px] border border-[#9CA3AF] sm:h-[200px]"
                          >
                            <div className="flex h-full items-center justify-center">
                              Upload License Here
                            </div>
                          </div>
                          <input
                            className="hidden"
                            name="license"
                            ref={licenseRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setFieldValue("license", e.target.files[0]);
                                setLicensePreview((prev) => ({
                                  ...prev,
                                  license: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                }));
                                setIsValidation((prev) => ({
                                  ...prev,
                                  license: true,
                                }));
                              }
                            }}
                          />
                          <ErrorMessage
                            name="license"
                            component="div"
                            className="error-input"
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-3 w-full">
                      <div className="mb-2 text-[16px] text-[#6f81a5]">
                        Car Image:
                      </div>
                      {carPreview.car || values.car_image ? (
                        <>
                          <div className="relative mr-4 flex h-[120px] w-full justify-center rounded-[4px] border border-[#9CA3AF] sm:h-[200px]">
                            <button
                              type="button"
                              onClick={(e) => {
                                setCarPreview((prev) => ({
                                  ...prev,
                                  car: null,
                                }));
                                values.car_image = null;
                                setIsValidation((prev: any) => ({
                                  ...prev,
                                  car_image: true,
                                }));
                              }}
                              className="absolute right-0 top-0 z-10"
                            >
                              <img src={crossIcon}></img>
                            </button>
                            <img
                              src={
                                carPreview.car ||
                                URL.createObjectURL(values.car_image)
                              }
                              style={{
                                objectFit: "contain",
                                height: "100%",
                                width: "auto",
                                cursor: "pointer",
                              }}
                              alt="img"
                            />
                          </div>
                          <div
                            className="my-2 inline-block"
                            onClick={() => {
                              downloadImage(
                                carPreview.car ||
                                  URL.createObjectURL(values.car_image)
                              );
                            }}
                          >
                            <CiSaveDown2 size={24} className="cursor-pointer" />
                          </div>
                          <ErrorMessage
                            name="car_image"
                            component="div"
                            className="error-input"
                          />
                        </>
                      ) : (
                        <div>
                          <div
                            onClick={() => {
                              carRef.current.click();
                            }}
                            className="relative mr-4 h-[120px] w-full rounded-[8px] border border-[#9CA3AF] sm:h-[200px]"
                          >
                            <div className="flex h-full items-center justify-center">
                              Upload Car Image Here
                            </div>
                          </div>
                          <input
                            className="hidden"
                            name="car_image"
                            ref={carRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setFieldValue("car_image", e.target.files[0]);
                                setCarPreview((prev) => ({
                                  ...prev,
                                  car: URL.createObjectURL(e.target.files[0]),
                                }));
                                setIsValidation((prev) => ({
                                  ...prev,
                                  car_image: true,
                                }));
                              }
                            }}
                          />
                          <ErrorMessage
                            name="car_image"
                            component="div"
                            className="error-input"
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-3 ms-6 w-full">
                      <div className="mb-2 text-[16px] text-[#6f81a5]">
                        Aadhar Card Image:
                      </div>
                      {aadharPreview.aadhar || values.aadhar ? (
                        <>
                          <div className="relative mr-4 flex h-[120px] w-full justify-center rounded-[4px] border border-[#9CA3AF] sm:h-[200px]">
                            <button
                              type="button"
                              onClick={(e) => {
                                setAadharPreview((prev) => ({
                                  ...prev,
                                  aadhar: null,
                                }));
                                values.aadhar = null;
                                setIsValidation((prev: any) => ({
                                  ...prev,
                                  aadhar: true,
                                }));
                              }}
                              className="absolute right-0 top-0 z-10"
                            >
                              <img src={crossIcon}></img>
                            </button>
                            <img
                              src={
                                aadharPreview.aadhar ||
                                URL.createObjectURL(values.aadhar)
                              }
                              style={{
                                objectFit: "contain",
                                height: "100%",
                                width: "auto",
                                cursor: "pointer",
                              }}
                              alt="img"
                            />
                          </div>
                          <div
                            className="my-2 inline-block"
                            onClick={() => {
                              downloadImage(
                                aadharPreview.aadhar ||
                                  URL.createObjectURL(values.aadhar)
                              );
                            }}
                          >
                            <CiSaveDown2 size={24} className="cursor-pointer" />
                          </div>
                          <ErrorMessage
                            name="aadhar"
                            component="div"
                            className="error-input"
                          />
                        </>
                      ) : (
                        <div>
                          <div
                            onClick={() => {
                              aadharRef.current.click();
                            }}
                            className="relative mr-4 h-[120px] w-full rounded-[8px] border border-[#9CA3AF] sm:h-[200px]"
                          >
                            <div className="flex h-full items-center justify-center">
                              Upload Aadhar Here
                            </div>
                          </div>
                          <input
                            className="hidden"
                            name="aadhar"
                            ref={aadharRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                setFieldValue("aadhar", e.target.files[0]);
                                setAadharPreview((prev) => ({
                                  ...prev,
                                  aadhar: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                }));
                                setIsValidation((prev: any) => ({
                                  ...prev,
                                  aadhar: true,
                                }));
                              }
                            }}
                          />
                          <ErrorMessage
                            name="aadhar"
                            component="div"
                            className="error-input"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="button-save-cancel mt-3 flex justify-end">
                    <Button
                      className=" cancel-button my-2 ms-1 sm:my-0"
                      onClick={() => {
                        window.history.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="save-button my-2 ms-1 bg-brand-500 dark:bg-brand-400 sm:my-0"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Card>
      )}
    </>
  );
};

export default DriverDetails;
