import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import dummyProfile from "../../assets/svg/dummyProfile.svg";

const ImageWithFallback: React.FC<{
  src: string;
  alt: string;
  fallbackSrc: string;
  className?: any;
}> = ({ src, alt, fallbackSrc, className }) => {
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = fallbackSrc;
  };
  return (
    <img src={src} alt={alt} onError={handleImageError} className={className} />
  );
};

const Navbar = (props: {
  onOpenSidenav?: () => void;
  brandText?: string;
  secondary?: boolean | string;
  handleSearch?: (e: React.SyntheticEvent<EventTarget>) => void;
  setSearchText?: (val: string) => void;
  flag?: boolean;
  sideBarUseState?: boolean;
  setSideBarUseState?: (val: boolean) => void;
}) => {
  const {
    handleSearch,

    sideBarUseState,
    setSideBarUseState,
  } = props;

  const name = localStorage.getItem("name");
  const key = localStorage.getItem("profileImgKey");

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl  dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1"></div>
      </div>
      <form onSubmit={handleSearch} style={{ display: "inherit" }}>
        <div className="me-3 mt-2 text-right">
          <h6 style={{ color: "#A1A5B7", fontSize: "12", fontWeight: "500" }}>
            Hello
          </h6>
          <h6 style={{ color: "#36364D", fontSize: "12", fontWeight: "600" }}>
            {name}
          </h6>
        </div>
        <div
          className={
            "relative mt-[3px] flex h-[61px] w-[150px] flex-grow items-center justify-around gap-2 rounded-xl  bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[150px] md:flex-grow-0 md:gap-1 xl:w-[60px] xl:gap-2"
          }
        >
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={() => setSideBarUseState(!sideBarUseState)}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              key !== null ? (
                <ImageWithFallback
                  src={key}
                  alt={"profile image"}
                  fallbackSrc={dummyProfile}
                  className="h-9 w-9 cursor-pointer rounded-sm"
                />
              ) : (
                <img
                  className="h-9 w-9 cursor-pointer"
                  src={dummyProfile}
                  alt="profile"
                />
              )
            }
            children={
              <div className="h-26 flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat py-3 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                {/* <div className="ml-4 mt-3 flex flex-col">
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href={`/admin/Profile/edit`}
                  >
                    Edit Profile
                  </a>
                </div>
                <div className="ml-4 mt-3 flex flex-col">
                  <a
                    className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                    href="/admin/resetPass"
                  >
                    Reset Password
                  </a>
                </div> */}
                <div className="ml-4 mt-3 flex flex-col">
                  <a
                    onClick={handleLogout}
                    href="/login"
                    className="text-sm font-medium text-red-500 hover:text-red-500"
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </form>
    </nav>
  );
};

export default Navbar;
