import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "components/loader/loader";
import ButtonEdit from "../../../assets/svg/ButtonEdit.svg";
import { createColumnHelper } from "@tanstack/react-table";
import "./driverslist.css";
import Navbar from "../../../components/navbar";
import { toaster } from "components/common/toaster";
import Header from "components/common/TableHeader";
import Table from "components/common/TableBody";
import { approveHandleApi, getDriversList } from "services/customAPI";
import { useDisclosure } from "@chakra-ui/react";
import CustomModal from "./modal";
import { CSVLink } from "react-csv";
import { BsFlagFill } from "react-icons/bs";

type RowObj = {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  createdAt: string;
  seats: number;
  pool: boolean;
  action: customFieldType2;
};

type customFieldType2 = {
  id: String;
  verified: boolean;
};

const columnHelper = createColumnHelper<RowObj>();

const Driver = (props: any) => {
  const { sideBarUseState, setSideBarUseState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [driverCsvData, setDriverCsvData] = useState([]);
  const currentPage = useRef<number>();
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const [state, setState] = useState({
    pageCount: 1,
    searchText: "",
    limit: 10,
    noData: false,
  });

  const headers = [
    { label: "Uid", key: "uid" },
    { label: "Phone Number", key: "phone_number" },
    { label: "License Doc Key", key: "license_card" },
    { label: "Car Doc Key", key: "car_image" },
    { label: "Aadhar Doc Key", key: "aadhar_image" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Seats", key: "no_of_seat" },
    { label: "Driver Type", key: "pool" },
    { label: "verified", key: "verified" },
    { label: "Date Added", key: "createdAt" },
    { label: "Seats", key: "no_of_seats" },
  ];

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Id</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          First Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Last Name
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("phone_number", {
      id: "phone_number",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Phone Number
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {`${info.getValue()} ${
            info.getValue().includes("#") ? "(deleted)" : ""
          }`}
        </p>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Date Added
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().split("T")[0]}
        </p>
      ),
    }),
    columnHelper.accessor("seats", {
      id: "seats",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Seats</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("pool", {
      id: "pool",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Driver Type
        </p>
      ),
      cell: (info) => (
        <p
          className={`text-lg font-bold  ${
            info.getValue() === true ? "text-navy-500" : "text-yellow-500"
          } dark:text-white`}
        >
          {/* {info.getValue() && info.getValue().toString() === "true" ? <BsFlagFill /> : <BsFlagFill />} */}
          <BsFlagFill />
        </p>
      ),
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <div className="flex">
            <div>
              <img
                src={ButtonEdit}
                alt="edit"
                className="me-2 cursor-pointer"
                onClick={() =>
                  navigate(`/admin/default/driverForm/${info.getValue().id}`)
                }
              />
            </div>
            <div>
              {info.getValue().verified ? (
                <button
                  disabled
                  className=" block min-w-[100px] rounded-[3px] bg-[#e6e6e6] p-1 px-2 text-center"
                >
                  Verified
                </button>
              ) : (
                <button
                  onClick={() => handleClickForModal(info.row.original)}
                  className="min-w-[100px] cursor-pointer rounded-[3px] bg-[#4dc3ff] p-1 px-2 text-center"
                >
                  Approve
                </button>
              )}
            </div>
          </div>
        </div>
      ),
    }),
  ];

  const handleClickForModal = (data: any) => {
    setIsLoading(true);
    setSelectedItem(data);
    onOpen();
    setIsLoading(false);
  };

  async function convertToUsableArray(Array: Array<any>) {
    return await Promise.all(
      Array.map(async (driver) => {
        return {
          id: driver?.uid,
          first_name: driver?.first_name,
          last_name: driver?.last_name,
          phone_number: driver?.phone_number,
          seats: driver?.no_of_seat,
          pool: driver?.pool,
          createdAt: driver?.createdAt,
          action: {
            id: driver?._id,
            verified: driver?.verified,
          },
        };
      })
    );
  }

  const getDriverListing = async () => {
    setIsLoading(true);
    try {
      const response: any = await getDriversList({
        page: currentPage.current,
        limit: state.limit,
        text: state.searchText.trim(),
      });
      console.log("respone", response);
      if (response?.data?.code === 200) {
        if (response?.data?.data?.[0].driver?.length === 0) {
          setState((prev: any) => ({
            ...prev,
            noData: true,
            pageCount: 0,
          }));
          setDriverCsvData([]);
        } else {
          setState((prev: any) => ({
            ...prev,
            pageCount: Math.ceil(
              response?.data?.data?.[0].totaldrivers?.[0]?.totalcount /
                state.limit
            ),
            searchText: state.searchText,
            noData: false,
          }));
          setDriverCsvData(
            response?.data?.data?.[0]?.driver.map((driver: any) => {
              let data = {
                uid: driver?.uid,
                phone_number: driver?.phone_number,
                first_name: driver?.first_name,
                last_name: driver?.last_name,
                no_of_seat: driver?.no_of_seat,
                pool: driver?.pool,
                verified: driver?.verified,
              };

              if (driver?.documents?.length > 0) {
                for (let i = 0; i < driver?.documents?.length; i++) {
                  data = {
                    ...data,
                    [driver?.documents?.[i]?.document_name]:
                      driver?.documents?.[i]?.document_key,
                  };
                }
              }
              return data;
            })
          );

          setTableData(
            await convertToUsableArray(response?.data?.data?.[0]?.driver)
          );
        }
      } else {
        throw Error(response.data?.message);
      }
    } catch (error: any) {
      toaster.errorToast(error?.response?.data?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const approveHandle = async (id: any) => {
    setIsLoading(true);
    onClose();
    try {
      const response: any = await approveHandleApi(id);
      if (response.data?.code === 200) {
        toaster.successToast(response.data?.message);
        currentPage.current = 1;
        getDriverListing();
      } else {
        toaster.errorToast(response.data?.message || "Something went wrong");
      }
    } catch (error: any) {
      toaster.errorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  function handlePageClick(e: any) {
    currentPage.current = e.selected + 1;
    getDriverListing();
  }

  const handleSearchSubmit = async (e: any) => {
    if (state.searchText.trim() === "") {
      return;
    }
    currentPage.current = 1;
    getDriverListing();
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      currentPage.current = 1;
      getDriverListing();
    } else {
      if (state.searchText === "") {
        currentPage.current = 1;
        getDriverListing();
      }
    }
  }, [state.searchText]);

  return (
    <div>
      <Navbar
        flag={true}
        brandText="Drivers"
        sideBarUseState={sideBarUseState}
        setSideBarUseState={setSideBarUseState}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="mt-4">
              <Header
                title="Drivers"
                setSearchText={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    searchText: value,
                  }))
                }
                showAddCategoryButton={false}
                handleSearchSubmit={handleSearchSubmit}
                searchText={state.searchText}
                showSelect={false}
                navigation="/"
              />
              <Table
                tableData={tableData}
                columns={columns}
                noData={state.noData}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: "#5E6278",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  {driverCsvData.length > 0 && (
                    <CSVLink
                      data={driverCsvData}
                      filename={"driverRecord.csv"}
                      className="rounded-sm bg-[#ff9966] px-2 py-1 text-[14px] text-white"
                      target="_blank"
                      headers={headers}
                    >
                      Download CSV
                    </CSVLink>
                  )}
                </div>
                <div>
                  <ReactPaginate
                    breakLabel=" .  .  . "
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={state.pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="previous-page-btn"
                    previousLinkClassName="page-link"
                    nextClassName="next-page-btn"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage.current - 1}
                  />
                </div>
              </div>
              <div>
                {isOpen && (
                  <CustomModal
                    onClose={onClose}
                    isOpen={isOpen}
                    name={selectedItem.first_name}
                    id={selectedItem.action.id}
                    approveHandle={approveHandle}
                  />
                )}
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default Driver;
