import React, { ReactNode } from "react";
import { FiSearch } from "react-icons/fi";
import Select from "react-select";
import add from "../../assets/svg/add.svg";
import filterIcon from "../../assets/svg/filterIcon.svg";
import arrow_down from "../../assets/svg/arrow_down.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  searchText?: any;
  setSearchText?: (value: string) => void;
  handleSearchSubmit?: any;
  planOptions?: any;
  plan?: any;
  handlePlanChange?: any;
  title?: string;
  navigation?: string;
  showSelect?: boolean; // Optional prop to control whether to show the Select input
  showAddCategoryButton?: boolean;
  showSearch?: boolean;
  showExtraButton?: boolean;
  extraButtonName?: string;
  extraButtonNavigation?:string;
  buttonName?:string

}

const filter = ({ children, ...props }: { children: ReactNode }) => (
  <div className="flex items-center justify-between" {...props}>
    <img src={filterIcon} className="ml-3" height={20} width={20} />
    {children}
  </div>
);

const arrowdown = () => (
  <div className="flex items-center justify-between">
    <img src={arrow_down} className="mr-3" height={15} width={15} />
  </div>
);

const Header: React.FC<Props> = ({
  setSearchText,
  handleSearchSubmit,
  planOptions,
  plan,
  handlePlanChange,
  searchText,
  title,
  navigation,
  showSelect = true,
  showAddCategoryButton = true,
  showSearch = true,
  showExtraButton = false,
  extraButtonName,
  extraButtonNavigation,
  buttonName

}) => {
  const navigate = useNavigate();

  return (
    <header className="relative flex items-center justify-between flex-col sm:flex-row">
      <div className="inline-flex">
        <div
          style={{ color: "#49308C" }}
          className="me-5 mt-2 text-md md:text-2xl font-bold text-navy-700 dark:text-white"
        >
          {title}
        </div>
        {showSearch && (
          <div className="flex h-[45px] items-center rounded-lg bg-white text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent the default form submission behavior
                handleSearchSubmit();
              }}
              className="flex h-full w-full"
            >
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-lg bg-white text-sm font-medium text-navy-700 !outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                style={{ outline: "none !important" }} // Add this line to remove the focus border
              />
              <div
                className="hover:shadow-linear flex h-full w-1/2 md:w-full cursor-pointer items-center justify-center rounded-lg border px-2"
                onClick={(e) => {
                  // e.preventDefault(); // Prevent default behavior of a click event
                  handleSearchSubmit(searchText);
                }}
              >
                <FiSearch
                  className="text-gray-400 dark:text-white"
                  // onClick={(e) => handleSearchSubmit(e)}
                />
              </div>
            </form>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between mt-2 sm:mt-0">
        {showSelect && (
          <div
            style={{
              width: "200px",
              marginRight: showAddCategoryButton ? "15px" : "",
            }}
          >
            <Select
              isSearchable={false}
              // className="w-400"
              className="custom-select"
              options={planOptions}
              onChange={(e: any) => {
                handlePlanChange(e);
              }}
              value={planOptions.filter(function (option: any) {
                return option.value === plan;
              })}
              name="plan"
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                option: (provided: any, state: any) => ({
                  ...provided,
                  backgroundColor: state.isSelected ? "#f2f3f7" : "white", // Change the background color here
                  color: "black", // Change the text color here
                  "&:hover": {
                    backgroundColor: "#f2f3f7", // Change the background color on hover
                  },
                }),
                control: (provided: any, state: any) => ({
                  ...provided,
                  borderColor: "#9ca3af",
                  // This line disable the blue border
                  boxShadow: state.isFocused ? "0 0 0 2px #9ca3af" : "#9ca3af",
                  "&:hover": {
                    // border: state.isFocused ? 0 : 0,
                  },
                }),
              }}
              components={{
                DropdownIndicator: arrowdown,
                IndicatorSeparator: () => null,
                ValueContainer: filter,
              }}
            />
          </div>
        )}
        {showExtraButton && (
          <div className="me-2" >
            <button
              className="my-sm-0 add-category-button text-[#ffffff] py-[6px] px-2 my-2 ms-1 flex items-center bg-brand-500 dark:bg-brand-400 rounded-[3px]"
              type="submit"
              onClick={() => navigate(extraButtonNavigation)}
            >
              <img src={add} className="mr-1" />
              {extraButtonName}
            </button>
          </div>
        )}
        {showAddCategoryButton && (
          <div >
            <button
              className="my-sm-0 add-category-button text-[#ffffff] py-[6px] px-2 my-2 ms-1 flex items-center bg-brand-500 dark:bg-brand-400 rounded-[3px]"
              type="submit"
              onClick={() => navigate(navigation)}
            >
              <img src={add} className="mr-1" />
              {buttonName}
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
